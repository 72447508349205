import {useEffect} from 'react'
import {addGitHubToTitle, setTitle} from '@github-ui/document-metadata'
import type {QueryClient} from '@github-ui/react-query'
import {useQueryClient} from '@github-ui/react-query'
import {useRouteMatches, type QueryOptionsWithKey} from './query-route'

export function TitleManager() {
  const matches = useRouteMatches()
  const queryClient = useQueryClient()
  useEffect(() => {
    // This means we're checking the "leaf nodes" first.
    // As soon as we find a title, we can break out of the loop.
    for (const match of matches.toReversed()) {
      if (!match.data?.route) continue
      const config = match.data?.queries.mainQuery
      if (!config) continue
      const title = getTitleFromQueryClient(queryClient, config.queryConfig)
      if (title) {
        setTitle(addGitHubToTitle(title))
        break
      }
    }
  }, [matches, queryClient])

  return null
}

export function getTitleFromQueryClient(
  queryClient: QueryClient,
  queryConfig: QueryOptionsWithKey,
): string | undefined {
  const cached = queryClient.getQueryData(queryConfig.queryKey) as {title?: string} | {meta?: {title: string}}
  if ('title' in cached && cached.title) {
    return cached?.title
  } else if ('meta' in cached && cached.meta) {
    return cached.meta.title
  }
}

try{ TitleManager.displayName ||= 'TitleManager' } catch {}